<script>
import simplebar from "simplebar-vue";
import { GetQuestionById, updateDocumentData } from "@/services/questions.js";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import { required } from "vuelidate/lib/validators";

/**
 * Chat Component
 */
export default {
  page: {
    title: "question",
    meta: [{ name: "description" }],
  },
  components: { Layout, PageHeader, simplebar },
  data() {
    return {
      data1: [],
      loading: false,
      user: JSON.parse(sessionStorage.getItem("authUserDetails")),

      form1: {
        editmessage: "lorem lorem llorem",
      },
      title: this.$t("question"),
      items: [
        {
          text: "Ylaw",
          href: "/",
        },
        {
          text: this.$t("menuitems.questions.text"),
          href: "/question/all",
        },
        {
          text: this.$t("question"),
          active: true,
        },
      ],
      submitted: false,
      form: {
        message: "",
      },
    };
  },
  validations: {
    form: {
      message: {
        required,
      },
    },
    form1: {
      editmessage: {
        required,
      },
    },
  },
  created() {
    this.getQuestion();
    setTimeout(() => {
      console.log(this.data1);
    }, 1000);
  },
  methods: {
    /**
     * Get the name of user
     */
    getQuestion() {
      this.loading = true;
      GetQuestionById(this.$route.params.id).then((data) => {
        this.data1 = data;
        // console.log(this.data1);
        this.loading = false;
      });
    },

    /**
     * Char form Submit
     */
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.form.$invalid) {
        return;
      } else {
        const message = this.form.message;
        const currentDate = new Date();
        console.log(message, currentDate);
        this.updateMessage({
          answer: message,
          question_statue: true,
          answerdby: this.user.name,
          answerDate: currentDate,
        });
        this.handleScroll();
      }
      this.submitted = false;
      this.form = {};
    },

    handleScroll() {
      if (this.$refs.current.$el) {
        setTimeout(() => {
          this.$refs.current.SimpleBar.getScrollElement().scrollTop =
            this.$refs.current.SimpleBar.getScrollElement().scrollHeight + 1500;
        }, 500);
      }
    },
    updateMessage(data) {
      this.loading = true;

      updateDocumentData(
        this.$route.params.id,
        data,
        this.data1.clientData.uid,
        this.data1?.questionData?.QuestionTitle
      )
        .then((res) => {
          if (res) {
            console.log("done");
            this.loading = false;
            this.getQuestion();
          } else {
            console.log("not done");
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          window.prompt(err);
        });
    },
    editm() {
      this.submitted = true;
      this.$v.form1.$touch();

      if (this.$v.form1.$invalid) {
        return;
      } else {
        const message = this.form1.editmessage;
        const currentDate = new Date();
        console.log(this.user.name);
        this.updateMessage({
          answer: message,
          answerdby: this.user.name,
          question_statue: true,
          answerDate: currentDate,
        });
        this.$bvModal.hide("modal-backdrop");
      }
      this.submitted = false;
    },
    editmodal() {
      this.form1.editmessage = this.data1?.questionData?.answer;
      this.$bvModal.show("modal-backdrop");
    },
  },
  mounted() {
    var container = document.querySelector(
      "#scrollElement .simplebar-content-wrapper"
    );
    //console.log(container)
    container ? container.scrollTo({ top: 200, behavior: "smooth" }) : "";
    var container2 = document.querySelector(
      "#containerElement .simplebar-content-wrapper"
    );
    container2 ? container2.scrollTo({ top: 500, behavior: "smooth" }) : "";
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-lg-flex mb-4">
      <div class="w-100 user-chat mt-4 mt-sm-0">
        <div class="p-3 px-lg-4 user-chat-border">
          <div class="row">
            <div class="col-md-4 col-6">
              <h5 class="font-size-15 mb-1 text-truncate">
                {{ data1?.questionData?.QuestionTitle }}
              </h5>
            </div>
          </div>
        </div>

        <div class="px-lg-2 chat-users">
          <div class="chat-conversation p-3">
            <simplebar
              style="max-height: 450px"
              id="containerElement"
              ref="current"
            >
              <ul class="list-unstyled mb-0 pr-3">
                <li>
                  <div class="conversation-list">
                    <div class="ctext-wrap">

                      <div class="conversation-name" >
                        <template v-if="data1?.questionData?.isAnonymous">
                              {{$t('anonymous')}}
                        </template>
                        <template v-else>
                          <router-link :to="{name:'clientPage',params:{id:data1?.clientData?.uid}}" class="client-link" v-if="data1?.clientData?.uid">
                            {{ data1?.clientData?.display_name }}
                          </router-link>
                        </template>
                      </div>
                      <div
                        class="ctext-wrap-content"
                        style="
                          word-wrap: break-word;
                          overflow-wrap: break-word;
                          overflow-y: auto;
                          max-width: 700px;
                        "
                      >
                        <p class="mb-0">
                          {{ data1?.questionData?.QuestionBody }}
                        </p>
                      </div>

                      <p class="chat-time mb-0" style="display: inline-block">
                        <i class="bx bx-time-five align-middle mr-1"></i>
                        {{ data1?.createdAt }}
                      </p>
                    </div>
                  </div>
                </li>
                <li class="right" v-if="data1?.questionData?.answer">
                  <div class="conversation-list">
                    <div class="ctext-wrap">
                      <div class="conversation-name">
                        {{ data1?.questionData?.answerdby }}
                      </div>
                      <div
                        class="ctext-wrap-content"
                        style="
                          word-wrap: break-word;
                          overflow-wrap: break-word;
                          overflow-y: auto;
                          max-width: 700px;
                        "
                      >
                        <p class="mb-0">
                          {{ data1?.questionData?.answer }}
                        </p>
                      </div>

                      <p class="chat-time mb-0" style="display: inline-block">
                        <i class="bx bx-time-five align-middle mr-1"></i>
                        {{ data1?.createdAt }}
                      </p>
                      <a
                        href="#"
                        style="text-align: right"
                        class="ml-2"
                        @click="editmodal"
                        >{{ $t("edit") }}</a
                      >
                    </div>
                  </div>
                </li>
                <b-modal
                  no-close-on-backdrop
                  id="modal-backdrop"
                  :title="$t('edit_answer')"
                  centered
                  title-class="font-18"
                >
                  <template #modal-footer="{}"><div></div></template>
                  <form @submit.prevent="editm" class="row">
                    <div class="col">
                      <div class="position-relative">
                        <b-form-textarea
                          type="text"
                          name="editmessage"
                          v-model="form1.editmessage"
                          class="form-control chat-input"
                          :placeholder="$t('enter_message') + ' ...'"
                          :class="{
                            'is-invalid':
                              submitted && $v.form1.editmessage.$error,
                          }"
                          rows="3"
                          max-rows="7"
                        />
                        <div
                          v-if="submitted && $v.form1.editmessage.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.form1.editmessage.required"
                            >{{ $t("please_type_message") }}.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-auto">
                      <button
                        type="submit"
                        class="btn btn-primary chat-send w-md waves-effect waves-light"
                      >
                        <span class="d-none d-sm-inline-block mr-2">{{
                          $t("send")
                        }}</span>
                        <i class="mdi mdi-send"></i>
                      </button>
                    </div>
                  </form>
                </b-modal>
              </ul>
            </simplebar>
          </div>
          <div class="px-lg-3" v-if="!data1?.questionData?.answer && !loading">
            <div class="p-3 chat-input-section">
              <form @submit.prevent="formSubmit" class="row">
                <div class="col">
                  <div class="position-relative">
                    <b-form-textarea
                      type="text"
                      v-model="form.message"
                      class="form-control chat-input"
                      :placeholder="$t('enter_message') + ' ...'"
                      :class="{
                        'is-invalid': submitted && $v.form.message.$error,
                      }"
                      rows="3"
                      max-rows="7"
                    />
                    <div
                      v-if="submitted && $v.form.message.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.message.required"
                        >{{ $t("please_type_message") }}.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <button
                    type="submit"
                    class="btn btn-primary chat-send w-md waves-effect waves-light"
                  >
                    <span class="d-none d-sm-inline-block mr-2">{{
                      $t("send")
                    }}</span>
                    <i class="mdi mdi-send"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="d-flex justify-content-center mb-3" v-if="loading">
            <b-spinner
              variant="primary"
              type="grow"
              label="Spinning"
            ></b-spinner>
          </div>
        </div>
      </div>
    </div>

    <!-- end row -->
  </Layout>
</template>
<style scoped>
.client-link{
  color: inherit;
}
</style>