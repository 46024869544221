import "firebase/firestore";
import firebase from "firebase/app";
import { pushNotification, create } from "./notification";
const db = firebase.firestore();
const collectionRef = db.collection("Questions");
const users = db.collection("users");
import moment from "moment";
const agentID = JSON.parse(localStorage.getItem("auth.currentUser")).uid;

export function GetAllQuestions() {
  return new Promise((resolve, reject) => {
    const data = [];

    collectionRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          const questionData = doc.data();
          const clientId = questionData.uid.id;
          let clientName = "";

          try {
            const clientDoc = await users.doc(clientId).get();

            if (clientDoc.exists) {
              clientName = clientDoc.data().display_name;
            } else {
              console.log("Client not found");
            }
          } catch (error) {
            console.error("Error fetching client data:", error);
          }
          data.push({
            id: doc.id,
            createdAt: nanosecondsToISO8601(doc.data().created_at.seconds),
            questionData,
            clientName,
            clientId,
          });
        });
        resolve(data);
      })
      .catch((error) => {
        console.error("Error getting documents:", error);
        reject(error);
      });
  });
}
export function GetQuestionsCount() {
  return new Promise((resolve, reject) => {
    collectionRef
      .get()
      .then((querySnapshot) => {
        resolve(querySnapshot.size);
      })
      .catch((error) => {
        console.error("Error getting documents:", error);
        reject(error);
      });
  });
}
export function GetAllNotAnswerd() {
  return new Promise((resolve, reject) => {
    const data = [];
    const query = collectionRef.where("question_statue", "==", false);
    query
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          const questionData = doc.data();
          const clientId = questionData.uid.id;
          let clientName = "";

          try {
            const clientDoc = await users.doc(clientId).get();

            if (clientDoc.exists) {
              clientName = clientDoc.data().display_name;
            } else {
              console.log("Client not found");
            }
          } catch (error) {
            console.error("Error fetching client data:", error);
          }
          data.push({
            id: doc.id,
            createdAt: nanosecondsToISO8601(doc.data().created_at.seconds),
            questionData,
            clientName,
            clientId,
          }); //contains the document data
        });
        resolve(data);
      })
      .catch((error) => {
        console.error("Error getting documents:", error);
        reject(error);
      });
  });
}
export async function GetQuestionById(id) {
  try {
    let doc = await collectionRef.doc(id).get();
    if (!doc.exists) {
      const findByQuestionID = await collectionRef
        .where("QuestionID", "==", +id)
        .limit(1)
        .get();
      findByQuestionID.forEach((el) => {
        doc = el;
      });
    }
    if (doc.exists) {
      const questionData = doc.data();
      const clientId = questionData.uid.id;
      let clientData = "";

      try {
        const clientDoc = await users.doc(clientId).get();

        if (clientDoc.exists) {
          clientData = clientDoc.data();
        } else {
          console.log("Client not found");
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }

      const data = {
        id: doc.id,
        createdAt: moment(
          nanosecondsToISO8601(questionData.created_at.seconds)
        ).calendar(),
        questionData,
        clientData,
        clientId,
      };
      return data;
    } else {
      console.log("Contract not found");
      throw new Error("Contract not found");
    }
  } catch (error) {
    console.error("Error getting contract document:", error);
    throw error;
  }
}
export async function updateDocumentData(docId, updatedData, clientID, qtitle) {
  const docRef = collectionRef.doc(docId);
  const body = ` تم الرد علي سؤالك بعنوان ${qtitle}`;
  const title = "تم الرد علي سؤالك";
  try {
    await docRef.update(updatedData);
    pushNotification(clientID, title, body);
    create(clientID, agentID, title, body, "Question", docId);
    return true;
  } catch (error) {
    console.error("Error updating document: ", error);
    return false;
  }
}
export function getDocumentsForSelectedMonth(selectedMonth) {
  return new Promise((resolve, reject) => {
    // Get the current year
    const currentYear = new Date().getFullYear();

    // Calculate the start and end dates for the selected month
    const startDate = new Date(currentYear, selectedMonth - 1, 1); // Subtract 1 to get the correct month index
    const endDate = new Date(currentYear, selectedMonth, 1); // Start of the next month

    // Create a query to get documents created in the selected month
    const query = collectionRef // Replace with your collection name
      .where("created_at", ">=", startDate)
      .where("created_at", "<", endDate);

    // Execute the query to get the documents
    query
      .get()
      .then((querySnapshot) => {
        const documents = [];
        querySnapshot.forEach((doc) => {
          // Access each document in the query result
          const data = {
            data: doc.data(),
            createdAt: nanosecondsToISO8601(doc.data().created_at.seconds),
          };
          documents.push(data);
        });
        resolve(documents);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Usage of the promise-based functio
function nanosecondsToISO8601(seconds) {
  const milliseconds = seconds * 1000; // Convert nanoseconds to milliseconds
  const date = new Date(milliseconds);
  return date;
}
